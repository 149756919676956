import axios from 'src/utils/axios';
import { API_BASE_URL } from 'src/config';

export const GET_PAN_FILES = '@panFile/get-pan-files';
export const REQUEST_PAN_FILES = '@panFile/request-pan-files';

export function getPanFiles(filter = null) {
  const params = {};

  if (filter != null) {
    if (filter.date != null) {
      params.date = filter.date;
    }

    if (filter.datefrom != null) {
      params.datefrom = filter.datefrom;
    }

    if (filter.dateto != null) {
      params.dateto = filter.dateto;
    }

    if (filter.query != null && filter.query != '') {
      params.query = filter.query;
    }
  }

  const request = axios.get(`${API_BASE_URL}/panfile`, { params });

  return (dispatch) => {
    dispatch({ type: REQUEST_PAN_FILES });

    request.then((response) => dispatch({
      type: GET_PAN_FILES,
      payload: { panFiles: response.data }
    })).catch((error) => {
      let message = '';
      if (error.response) {
        message = error.response.data;
      }
      dispatch({
        type: GET_PAN_FILES,
        payload: { panFiles: [], message }
      });
    });
  };
}
