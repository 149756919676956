export const THEMES = {
  LIGHT: 'LIGHT',
  ONE_DARK: 'ONE_DARK',
  UNICORN: 'UNICORN',
  ONION: 'ONION'
};

export const DEFAULT_LIMIT = 100;
export const DEFAULT_LIMIT_OPTIONS = [5, 10, 25, 100];

export const DEFAULT_ORDER_BY = 'asc';
